import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  Observable,
} from "@apollo/client";

// Additional for Authenticated Apollo
import { onError } from "@apollo/link-error";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { getAccessToken, setAccessToken } from "../locals/accessToken";
import { RealmAccessToken } from "../models/Authentication";
import { User } from "../generated/graphql";

// Authenticated Apollo
const cache = new InMemoryCache({});
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: ZenObservable.Subscription;
      Promise.resolve(operation)
        .then((operation) => {
          const token = getAccessToken();
          console.log("Token:", token);
          if (token) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${token}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const REALM_REFRESH = process.env.REACT_APP_REALM_REFRESH;
if (!REALM_REFRESH) {
  throw new Error("Invalid refresh URL");
}

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "access_token",
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();
    if (!token) {
      return false;
    }
    try {
      const { exp } = jwtDecode(token) as RealmAccessToken<User>;
      return Date.now() < exp * 1000;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  fetchAccessToken: () => {
    //console.log("Refresh:", localStorage.getItem("refreshToken"));
    const decoded = jwtDecode(localStorage.getItem("refreshToken")!);
    console.log("Decoded:", decoded);
    //console.log("Refresh Expiry:", new Date(decoded.exp * 1000));
    return fetch(REALM_REFRESH, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
    });
  },
  handleFetch: (accessToken) => {
    //console.log("HandleFetch:", accessToken);
    setAccessToken(accessToken);
  },
  handleError: (err) => {
    console.warn("Your refresh token is invalid. Try to relogin");
    console.error(err);
  },
});

const REALM_URI = process.env.REACT_APP_REALM_URI;
if (!REALM_URI) {
  throw new Error("No Realm URI");
}

const link: ApolloLink = ApolloLink.from([
  tokenRefreshLink,
  onError(({ graphQLErrors, networkError }) => {
    console.log(graphQLErrors);
    console.log(networkError);
  }),
  requestLink,
  new HttpLink({
    uri: REALM_URI,
    credentials: "include",
  }),
]);

export const client = new ApolloClient({ link, cache });
