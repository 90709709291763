import React, { FC } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { matchPath } from "react-router";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useBreakpoint } from "../shared/hook/useBreakpoint";
import { Icon } from "@iconify/react";
import caretBack from "@iconify-icons/ion/caret-back";
import "tailwindcss/tailwind.css";
import { useRealmApp } from "../shared/context/RealmContext";
import {usePalette} from "../shared/hook/usePalette";

export const AppHeader: FC = () => {
  const palette = usePalette();
  const history = useHistory();
  const location = useLocation();
  const pathMatch = matchPath(location.pathname, "/numbers/:id");
  const backButtonAnimState = pathMatch !== null ? "show" : "hide";

  const { isAuthenticated, logOut } = useRealmApp();

  const backButtonVariants: Variants = {
    hide: {
      opacity: 0,
      scale: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
    },
  };

  const breakpoint = useBreakpoint();
  let titleAnimState;
  if (breakpoint === "sm") {
    titleAnimState = backButtonAnimState;
  } else titleAnimState = "inactive";

  const backButton = (
    <AnimatePresence>
      <motion.div
        layout
        animate={backButtonAnimState}
        variants={backButtonVariants}
        transition={{
          ease: "easeInOut",
          duration: 0.25,
        }}
        className="sm:hidden w-12 h-12 my-auto"
      >
        <Link className="sm:hidden h-auto my-auto" to="/numbers">
          <Icon icon={caretBack} className="h-12 w-12 pl-4" color="white" />
        </Link>
      </motion.div>
    </AnimatePresence>
  );

  const title = (
    <motion.h2
      animate={titleAnimState}
      variants={{
        hide: { translateX: -48 },
        show: { translateX: 0 },
        inactive: { translateX: 0 },
      }}
      transition={{
        ease: "easeInOut",
        duration: 0.2,
      }}
      className="min-w-0 px-4 py-4 text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate"
    >
      Embark
    </motion.h2>
  );

  const logOutButton = isAuthenticated && (
    <div className="col-span-1 items-right">
      <button
        onClick={async () => {
          await logOut();
          history.push("/auth");
        }}
        type="button"
        className="inline-flex items-center px-2.5 mr-4 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-black bg-white focus:outline-none"
      >
        Log Out
      </button>
    </div>
  );

  return (
    <header className="sticky z-20 top-0">
      <div
        style={{
          backgroundImage: `linear-gradient(to right, ${palette.primaryColorHex}, ${palette.secondaryColorHex})`
        }}
        className={`flex flex-row justify-between h-auto items-center border-b`}
      >
        <div className="flex flex-row">
          {backButton}
          {title}
        </div>
        {logOutButton}
      </div>
    </header>
  );
};
