import React, { ReactElement, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { motion } from "framer-motion";
import {usePalette} from "../../shared/hook/usePalette";

export interface ListItemProps<T> {
  item: T;
  isSelected: boolean;
  titleFunc: (t: T) => string;
  descriptionFunc?: (t: T) => string;
}

const FlatCard: <T extends { _id: string }>(
  props: ListItemProps<T>
) => ReactElement = ({ item, isSelected, titleFunc, descriptionFunc }) => {
  const palette = usePalette();
  const [hovered, setHovered] = useState(false);
  const title = (
    <motion.div
      animate={{ color: hovered ? palette.primaryColorHex : "#000000" }}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      className="text-m text-left leading-5 font-medium text-gray-900"
    >
      {titleFunc(item)}
    </motion.div>
  );

  const description = descriptionFunc && (
    <span className="text-sm text-left leading-5 text-gray-500">
      {descriptionFunc(item)}
    </span>
  );

  return (
    <motion.li
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      className="flex w-auto"
    >
      <div
        className={`px-4 py-4 cursor-pointer flex flex-col align-start w-full ${
          isSelected ? "bg-gray-100" : ""
        }`}
      >
        {title}
        {description}
      </div>
    </motion.li>
  );
};

export interface ListProps<T> {
  items: T[];
  titleFunc: (t: T) => string;
  descriptionFunc?: (t: T) => string;
  location: string;
}

export function FlatList<T extends { _id: string }>(
  props: ListProps<T>
): ReactElement<ListProps<T>> {
  const { items, titleFunc, descriptionFunc, location } = props;
  const idMatch = useRouteMatch<{ id: string }>(`/${location}/:id`);
  const selected = items.find((item) => item._id === idMatch?.params.id);

  const cards = items.map((item, i) => {
    const isSelected = selected !== undefined && selected._id === item._id;
    const card = FlatCard<T>({ item, isSelected, descriptionFunc, titleFunc });

    return (
      <Link to={`/${location}/${item._id}`} key={i}>
        {card}
      </Link>
    );
  });
  return (
    <ul className="flex flex-col w-full divide-y divide-gray-200">{cards}</ul>
  );
}
