import React, { FC } from "react";
import "./App.css";
import "tailwindcss/tailwind.css";
import { AppHeader } from "./components/AppHeader";
import { Switch, Route, Redirect } from "react-router-dom";
import { NumbersPage } from "./pages/Numbers";
import { RealmAppContextProvider } from "./shared/context/RealmContext";
import {AuthenticationPage} from './pages/Authentication';
import ProtectedRoute from "./shared/ProtectedRoute";
import { AnimatePresence } from "framer-motion";

const Providers: FC = ({ children }) => {
  return (
    <>
      <RealmAppContextProvider>{children}</RealmAppContextProvider>
    </>
  );
};

const App: FC = () => {
  return (
    <div className="App h-screen overflow-y-hidden">
      <Providers>
        <AppHeader />
        <AnimatePresence>
          <Switch>
            {/*<Route path="/forgotpassworod" component={ForgotPasswordPage}></Route>*/}
            <Route exact path="/auth" component={AuthenticationPage} />
            <ProtectedRoute path="/numbers" component={NumbersPage} />
            <Redirect exact from="/" to="/numbers" />
            <Redirect exact from="" to="/numbers" />
          </Switch>
        </AnimatePresence>
      </Providers>
    </div>
  );
};

export default App;
