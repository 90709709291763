import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { RealmAppContext } from "./context/RealmContext";

// TODO: Refactor to prevent both children and component
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute: FC<
  {
    component?: FC;
  } & RouteProps
> = ({ component: Component, children, ...rest }) => {
  return (
    <RealmAppContext.Consumer>
      {(auth) => (
        <>
          {/* <Logging auth={auth} /> */}
          <Route
            {...rest}
            render={(routeProps) =>
              auth.isAuthenticated ? (
                Component ? (
                  <Component {...routeProps} {...rest} />
                ) : (
                  children
                )
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth",
                    state: { from: routeProps.location },
                  }}
                />
              )
            }
          />
        </>
      )}
    </RealmAppContext.Consumer>
  );
};

export default PrivateRoute;
