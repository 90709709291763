import React, { FC } from "react";
import {
  FieldError,
  useForm,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useRealmApp } from "../shared/context/RealmContext";
import { motion } from "framer-motion";
import { Expand } from "../components/reusable/Expand";
import { usePalette } from "../shared/hook/usePalette";

type SignInData = {
  email: string;
  password: string;
}

export const AuthenticationPage: FC = () => {
  const { logIn, isAuthenticated } = useRealmApp();
  const history = useHistory();
  if (isAuthenticated) {
    // Jump in if user is already authenticated
    history.replace("/numbers");
  }

  const { primaryColorHex, cleanTransition } = usePalette();

  const {
    register,
    handleSubmit,
    errors,
    setError
  } = useForm<SignInData>({
    defaultValues: {
      password: "",
      email: "",
    },
  });

  const onLogin = handleSubmit(async (data) => {
    console.log("onLogin:", data);
    const { email, password } = data;

    const err = await logIn(email, password);
    if (err) {
      console.log("Login error", err);
      switch (err.code) {
        case "AuthError":
          switch (err.msg) {
            default:
              console.log("Unknown error");
              break;
          }
          break;
        case "InvalidPassword":
          setError("password", {
            message: "Invalid credentials!",
            shouldFocus: true,
          });
          break;
        default:
          console.log("Unknown error");
          break;
      }
    } else {
      history.replace("/numbers");
    }
  });

  console.log("Errors:", errors);

  const InputErrText: FC<{ err: FieldError | undefined }> = ({ err }) => {
    return (
      <Expand
        className="text-sm text-red-500 text-left pl-4"
        expanded={Boolean(err && err.type !== "required")}
      >
        <p className="my-1">{err && err?.type !== "required" && err.message}</p>
      </Expand>
    );
  };

  const emailAddressInput = (
    <motion.div layout transition={cleanTransition}>
      <label htmlFor="email-address" className="sr-only">
        Email address
      </label>
      <input
        id="email-address"
        name="email"
        type="email"
        required
        style={{
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          marginBottom: "0rem",
        }}
        className={`rounded-t-md transition duration-500 relative block w-full px-3 py-2 border border-gray-300 
        placeholder-gray-500 text-gray-900 sm:text-sm  
        ${
          errors.email
            ? "focus:outline-none ring-red-500 border-red-500 z-10"
            : `focus:outline-none focus:z-10 `
        }`}
        placeholder={`Email address ${
          errors.email?.type === "required" ? "(required)" : ""
        }`}
        ref={register}
      />
    </motion.div>
  );

  const passwordInput = (
    <motion.div layout>
      <label htmlFor="password" className="sr-only">
        Password
      </label>
      <input
        style={{
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        }}
        id="password"
        name="password"
        type="password"
        required
        className={`rounded-b-md transition duration-500 relative block w-full px-3 py-2 border border-gray-300 
        placeholder-gray-500 text-gray-900 sm:text-sm  
        ${
          errors.password
            ? "focus:outline-none ring-red-500 border-red-500 z-10"
            : `focus:outline-none focus:z-10`
        }`}
        placeholder={`Password ${
          errors.password?.type === "required" ? "(required)" : ""
        }`}
        ref={register}
      />
    </motion.div>
  );

  const signInButton = (
    <div
      style={{
        marginTop: "1rem",
        marginBottom: "1rem",
        top: "0rem",
        height: "2.5rem",
        opacity: 1,
        backgroundColor: primaryColorHex
      }}
      onClick={onLogin}
      className={`flex cursor-pointer py-2 group relative w-full justify-center px-4 border border-transparent text-sm 
      font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2`}
    >
      <span className="flex-1 absolute left-0 inset-y-0 flex items-center pl-3">
        {/* Heroicon name: lock-closed */}
        <svg
          className="h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      Sign in
    </div>
  );

  const loginRegisterForm = (
    <motion.form layout>
      <InputErrText err={errors.email} />
      <div className="-space-y-px">
        {emailAddressInput}
        {passwordInput}
      </div>
      <InputErrText err={errors.password} />
    </motion.form>
  );

  const loginRegisterSection = (
    <div>
      {loginRegisterForm}
      {signInButton}
    </div>
  );

  const title = (
    <motion.div
      layout
      className={`overflow-y-hidden h-10 mb-4 text-2xl`}
      style={{color: primaryColorHex}}
    >
      <div
        className="relative"
      >
        <p className="h-10">Please sign in</p>
      </div>
    </motion.div>
  );

  const pseudoModal = (
    <div
      style={{ maxWidth: "28rem" }}
      className={`space-y-0 max-w-md rounded-lg w-full px-4 py-4 sm:px-6 bg-white lg:px-8`}
      key="pseudoModalDiv"
    >
      {title}
      {loginRegisterSection}
    </div>
  );

  return (
    <div className="flex justify-center items-center h-full">
      {pseudoModal}
    </div>
  );
};
