import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

type ExpandState = "expanded" | "expanding" | "contracted" | "contracting";

export const Expand: FC<{
  expanded: boolean;
  className?: string;
}> = React.memo(({ children, className, expanded }) => {
  const [state, setState] = useState<ExpandState>(
    expanded ? "expanded" : "contracted"
  );

  // console.log("Render", state, expanded);

  useEffect(() => {
    // console.log("useEffect", state, expanded);
    let newState: ExpandState = state;
    switch (state) {
      case "expanded":
        if (!expanded) newState = "contracting";
        break;
      case "expanding":
        if (!expanded) newState = "contracting";
        break;
      case "contracted":
        if (expanded) newState = "expanding";
        break;
      case "contracting":
        if (expanded) newState = "expanding";
        break;
    }

    if (newState !== state) {
      // console.log(`setState(${newState})`);
      setState(newState);
    }
  }, [expanded, state]);

  const onAnimComplete = () => {
    // console.log("onAnimComplete", state, expanded);
    switch (state) {
      case "expanding":
        setState("expanded");
        break;
      case "contracting":
        setState("contracted");
        break;
    }
  };

  const initial: string = {
    expanding: "contract",
    expanded: "expand",
    contracting: "expand",
    contracted: "contract",
  }[state];

  const animate = {
    expanding: "expand",
    expanded: "expand",
    contracting: "contract",
    contracted: "contract",
  }[state];

  // console.log(initial, animate);

  return (
    <motion.div
      layout
      initial={initial}
      animate={animate}
      exit={"contracted"}
      variants={{
        expand: { height: "auto" },
        contract: { height: "0px" },
      }}
      transition={{ duration: 0.3, ease: "linear" }}
      onAnimationComplete={onAnimComplete}
      className={`overflow-y-hidden ${className || ""}`}
    >
      {children}
    </motion.div>
  );
});
