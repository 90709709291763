import React, { useReducer, createContext, Reducer, FC } from "react";
import * as RealmWeb from "realm-web";
import { ApolloConsumer } from "@apollo/react-hooks";
import jwtDecode from "jwt-decode";
import { RealmAccessToken, RefreshResponse } from "../../models/Authentication";
import { EJSON } from "bson";
import { setAccessToken } from "../../locals/accessToken";
import { User } from "../../generated/graphql";

// Local storage keys
const USER_DATA_KEY = "userData";
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

// Check env vars are present
const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
if (!REALM_APP_ID) {
  throw new Error("Invalid app ID");
}

const REALM_REFRESH = process.env.REACT_APP_REALM_REFRESH;
if (!REALM_REFRESH) {
  throw new Error("Invalid refresh URL");
}

const REALM_REGISTER = process.env.REACT_APP_REALM_REGISTER;
if (!REALM_REGISTER) {
  throw new Error("Invalid register URL");
}

// Connect to Realm
const app = new RealmWeb.App({ id: REALM_APP_ID });

// Create authorization reducer and requisite types
interface AuthState {
  isAuthenticated: boolean;
  isConfirmed: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  userData: User | null;
}

type AuthAction = LoginAuthAction | RefreshAuthAction | LogoutAuthAction;

interface BaseAuthAction {
  type: "login" | "refresh" | "logout";
}

interface LoginAuthAction extends BaseAuthAction {
  type: "login";
  payload: {
    isConfirmed: boolean;
    accessToken: string;
    refreshToken: string;
    userData: User;
  };
}

interface RefreshAuthAction extends BaseAuthAction {
  type: "refresh";
  payload: {
    accessToken: string;
    userData: User;
  };
}

interface LogoutAuthAction extends BaseAuthAction {
  type: "logout";
}

const authReducer: Reducer<AuthState, AuthAction> = (state, action) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        isAuthenticated: true,
        ...action.payload,
      };
    case "refresh":
      return {
        ...state,
        isAuthenticated: true,
        refreshToken: null,
        ...action.payload,
      };
    case "logout":
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        userData: null,
      };
  }
};

export interface RealmAppAuthErr {
  code: string;
  msg: string;
}

// Create RealmAppContext and its provider
export const RealmAppContext = createContext<
  AuthState & {
    appId: string;
    refresh: () => Promise<void>;
    logIn: (email: string, password: string) => Promise<RealmAppAuthErr | void>;
    logOut: () => Promise<void>;
  }
  // @ts-ignore
>(null);

export const RealmAppContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(
    authReducer,
    {
      isAuthenticated: false,
      isConfirmed: false,
      accessToken: null,
      refreshToken: null,
      userData: null,
    }
  );

  // Read cached values from previous session
  const accessTokenInStorage = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshTokenInStorage = localStorage.getItem(REFRESH_TOKEN_KEY);
  const userInStorage = localStorage.getItem(USER_DATA_KEY);

  // Resume previous session (if possible)
  if (refreshTokenInStorage && userInStorage) {
    if (!accessTokenInStorage) {
      throw new Error("Had refresh but not access token");
    }

    if (!state.isAuthenticated) {
      const decodedAccessToken: RealmAccessToken<User> = jwtDecode(
        accessTokenInStorage
      );
      const userData = decodedAccessToken.user_data;

      dispatch({
        type: "login",
        payload: {
          isConfirmed: Boolean(userData),
          accessToken: accessTokenInStorage,
          refreshToken: refreshTokenInStorage,
          userData: EJSON.parse(userInStorage) as User,
        },
      });
    }
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <RealmAppContext.Provider
          value={{
            ...state,
            appId: REALM_APP_ID,

            refresh: async () => {
              console.log(
                "Refresh Init:",
                localStorage.getItem(REFRESH_TOKEN_KEY)
              );

              // Post refresh token to session refresh endpoint
              const refreshResponse: RefreshResponse = (await fetch(
                REALM_REFRESH,
                {
                  method: "POST",
                  headers: { Authorization: `Bearer ${state.refreshToken}` },
                }
              ).then((res) => res.json())) as RefreshResponse;

              const accessToken = refreshResponse.access_token;
              console.log("Refreshed Access Token:", accessToken);
              const decodedAccessToken: RealmAccessToken<User> = jwtDecode(
                accessToken
              );
              const userData = decodedAccessToken.user_data;

              // Write refreshed access token and user data and to local storage
              console.log("REFRESH USER:", userData);
              localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
              setAccessToken(accessToken);
              localStorage.setItem(USER_DATA_KEY, EJSON.stringify(userData));

              dispatch({
                type: "refresh",
                payload: {
                  accessToken,
                  userData,
                },
              });

              client.stop();
              await client.resetStore();
            },

            logIn: async (email: string, password: string) => {
              const credentials = RealmWeb.Credentials.emailPassword(
                email,
                password
              );

              let auth;
              try {
                auth = await app.logIn(credentials);
                console.log("App post-login:", app);
              } catch (e) {
                console.log("Login error", e);

                return {
                  code: e.errorCode,
                  msg: e.error,
                };
              }

              const accessToken = auth.accessToken!;
              const refreshToken = auth.refreshToken!;
              const decodedAccessToken: RealmAccessToken<User> = jwtDecode(
                accessToken
              );
              const userData: User = decodedAccessToken.user_data;
              if (!userData) {
                throw new Error("Access token carried no user data");
              }
              console.log(accessToken);

              localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
              setAccessToken(accessToken);
              localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
              localStorage.setItem(USER_DATA_KEY, EJSON.stringify(userData));

              dispatch({
                type: "login",
                payload: {
                  isConfirmed: Boolean(userData),
                  refreshToken,
                  accessToken,
                  userData,
                },
              });

              client.stop();
              await client.resetStore();
            },

            logOut: async () => {
              console.log("Pre-logout App:", app);

              try {
                await app.currentUser?.logOut();

                // Flush credentials from local storage
                [
                  ACCESS_TOKEN_KEY,
                  REFRESH_TOKEN_KEY,
                  USER_DATA_KEY,
                ].forEach((key) => localStorage.removeItem(key));
                setAccessToken("");

                // Clear state
                dispatch({
                  type: "logout",
                });

                client.stop();
                await client.resetStore();
              } catch (e) {
                console.log(e);
              }
            },
          }}
        >
          {children}
        </RealmAppContext.Provider>
      )}
    </ApolloConsumer>
  );
};

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error("You must call useRealmApp() inside of a <RealmApp />.");
  }
  return app;
};
