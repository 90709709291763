import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ObjectId: any;
};

export type DeleteManyPayload = {
  __typename?: 'DeleteManyPayload';
  deletedCount: Scalars['Int'];
};

export type InsertManyPayload = {
  __typename?: 'InsertManyPayload';
  insertedIds: Array<Maybe<Scalars['ObjectId']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteManyPhoneNumbers?: Maybe<DeleteManyPayload>;
  deleteManyUsers?: Maybe<DeleteManyPayload>;
  deleteOnePhoneNumber?: Maybe<PhoneNumber>;
  deleteOneUser?: Maybe<User>;
  insertManyPhoneNumbers?: Maybe<InsertManyPayload>;
  insertManyUsers?: Maybe<InsertManyPayload>;
  insertOnePhoneNumber?: Maybe<PhoneNumber>;
  insertOneUser?: Maybe<User>;
  replaceOnePhoneNumber?: Maybe<PhoneNumber>;
  replaceOneUser?: Maybe<User>;
  updateManyPhoneNumbers?: Maybe<UpdateManyPayload>;
  updateManyUsers?: Maybe<UpdateManyPayload>;
  updateOnePhoneNumber?: Maybe<PhoneNumber>;
  updateOneUser?: Maybe<User>;
  upsertOnePhoneNumber?: Maybe<PhoneNumber>;
  upsertOneUser?: Maybe<User>;
};


export type MutationDeleteManyPhoneNumbersArgs = {
  query?: Maybe<PhoneNumberQueryInput>;
};


export type MutationDeleteManyUsersArgs = {
  query?: Maybe<UserQueryInput>;
};


export type MutationDeleteOnePhoneNumberArgs = {
  query: PhoneNumberQueryInput;
};


export type MutationDeleteOneUserArgs = {
  query: UserQueryInput;
};


export type MutationInsertManyPhoneNumbersArgs = {
  data: Array<PhoneNumberInsertInput>;
};


export type MutationInsertManyUsersArgs = {
  data: Array<UserInsertInput>;
};


export type MutationInsertOnePhoneNumberArgs = {
  data: PhoneNumberInsertInput;
};


export type MutationInsertOneUserArgs = {
  data: UserInsertInput;
};


export type MutationReplaceOnePhoneNumberArgs = {
  data: PhoneNumberInsertInput;
  query?: Maybe<PhoneNumberQueryInput>;
};


export type MutationReplaceOneUserArgs = {
  data: UserInsertInput;
  query?: Maybe<UserQueryInput>;
};


export type MutationUpdateManyPhoneNumbersArgs = {
  query?: Maybe<PhoneNumberQueryInput>;
  set: PhoneNumberUpdateInput;
};


export type MutationUpdateManyUsersArgs = {
  query?: Maybe<UserQueryInput>;
  set: UserUpdateInput;
};


export type MutationUpdateOnePhoneNumberArgs = {
  query?: Maybe<PhoneNumberQueryInput>;
  set: PhoneNumberUpdateInput;
};


export type MutationUpdateOneUserArgs = {
  query?: Maybe<UserQueryInput>;
  set: UserUpdateInput;
};


export type MutationUpsertOnePhoneNumberArgs = {
  data: PhoneNumberInsertInput;
  query?: Maybe<PhoneNumberQueryInput>;
};


export type MutationUpsertOneUserArgs = {
  data: UserInsertInput;
  query?: Maybe<UserQueryInput>;
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  _id: Scalars['ObjectId'];
  msg: Scalars['String'];
  note: Scalars['String'];
  number: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  phoneNumber?: Maybe<PhoneNumber>;
  phoneNumbers: Array<Maybe<PhoneNumber>>;
  user?: Maybe<User>;
  users: Array<Maybe<User>>;
};


export type QueryPhoneNumberArgs = {
  query?: Maybe<PhoneNumberQueryInput>;
};


export type QueryPhoneNumbersArgs = {
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<PhoneNumberQueryInput>;
  sortBy?: Maybe<PhoneNumberSortByInput>;
};


export type QueryUserArgs = {
  query?: Maybe<UserQueryInput>;
};


export type QueryUsersArgs = {
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<UserQueryInput>;
  sortBy?: Maybe<UserSortByInput>;
};

export type UpdateManyPayload = {
  __typename?: 'UpdateManyPayload';
  matchedCount: Scalars['Int'];
  modifiedCount: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  realmId: Scalars['String'];
};

export enum PhoneNumberSortByInput {
  MsgAsc = 'MSG_ASC',
  MsgDesc = 'MSG_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum UserSortByInput {
  RealmidAsc = 'REALMID_ASC',
  RealmidDesc = 'REALMID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type PhoneNumberInsertInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  msg: Scalars['String'];
  note: Scalars['String'];
  number: Scalars['String'];
};

export type PhoneNumberQueryInput = {
  AND?: Maybe<Array<PhoneNumberQueryInput>>;
  OR?: Maybe<Array<PhoneNumberQueryInput>>;
  _id?: Maybe<Scalars['ObjectId']>;
  _id_exists?: Maybe<Scalars['Boolean']>;
  _id_gt?: Maybe<Scalars['ObjectId']>;
  _id_gte?: Maybe<Scalars['ObjectId']>;
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  _id_lt?: Maybe<Scalars['ObjectId']>;
  _id_lte?: Maybe<Scalars['ObjectId']>;
  _id_ne?: Maybe<Scalars['ObjectId']>;
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  msg?: Maybe<Scalars['String']>;
  msg_exists?: Maybe<Scalars['Boolean']>;
  msg_gt?: Maybe<Scalars['String']>;
  msg_gte?: Maybe<Scalars['String']>;
  msg_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  msg_lt?: Maybe<Scalars['String']>;
  msg_lte?: Maybe<Scalars['String']>;
  msg_ne?: Maybe<Scalars['String']>;
  msg_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  note_exists?: Maybe<Scalars['Boolean']>;
  note_gt?: Maybe<Scalars['String']>;
  note_gte?: Maybe<Scalars['String']>;
  note_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  note_lt?: Maybe<Scalars['String']>;
  note_lte?: Maybe<Scalars['String']>;
  note_ne?: Maybe<Scalars['String']>;
  note_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_exists?: Maybe<Scalars['Boolean']>;
  number_gt?: Maybe<Scalars['String']>;
  number_gte?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_lt?: Maybe<Scalars['String']>;
  number_lte?: Maybe<Scalars['String']>;
  number_ne?: Maybe<Scalars['String']>;
  number_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PhoneNumberUpdateInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  _id_unset?: Maybe<Scalars['Boolean']>;
  msg?: Maybe<Scalars['String']>;
  msg_unset?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  note_unset?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  number_unset?: Maybe<Scalars['Boolean']>;
};

export type UserInsertInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  realmId: Scalars['String'];
};

export type UserQueryInput = {
  AND?: Maybe<Array<UserQueryInput>>;
  OR?: Maybe<Array<UserQueryInput>>;
  _id?: Maybe<Scalars['ObjectId']>;
  _id_exists?: Maybe<Scalars['Boolean']>;
  _id_gt?: Maybe<Scalars['ObjectId']>;
  _id_gte?: Maybe<Scalars['ObjectId']>;
  _id_in?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  _id_lt?: Maybe<Scalars['ObjectId']>;
  _id_lte?: Maybe<Scalars['ObjectId']>;
  _id_ne?: Maybe<Scalars['ObjectId']>;
  _id_nin?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  realmId?: Maybe<Scalars['String']>;
  realmId_exists?: Maybe<Scalars['Boolean']>;
  realmId_gt?: Maybe<Scalars['String']>;
  realmId_gte?: Maybe<Scalars['String']>;
  realmId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  realmId_lt?: Maybe<Scalars['String']>;
  realmId_lte?: Maybe<Scalars['String']>;
  realmId_ne?: Maybe<Scalars['String']>;
  realmId_nin?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserUpdateInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  _id_unset?: Maybe<Scalars['Boolean']>;
  realmId?: Maybe<Scalars['String']>;
  realmId_unset?: Maybe<Scalars['Boolean']>;
};


export type UpdateNumberMutationVariables = Exact<{
  _id?: Maybe<Scalars['ObjectId']>;
  msg?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
}>;


export type UpdateNumberMutation = (
  { __typename?: 'Mutation' }
  & { updateOnePhoneNumber?: Maybe<(
    { __typename?: 'PhoneNumber' }
    & Pick<PhoneNumber, '_id' | 'msg' | 'note' | 'number'>
  )> }
);

export type MyPhoneNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPhoneNumbersQuery = (
  { __typename?: 'Query' }
  & { phoneNumbers: Array<Maybe<(
    { __typename?: 'PhoneNumber' }
    & Pick<PhoneNumber, '_id' | 'msg' | 'note' | 'number'>
  )>> }
);


export const UpdateNumberDocument = gql`
    mutation UpdateNumber($_id: ObjectId, $msg: String, $note: String) {
  updateOnePhoneNumber(query: {_id: $_id}, set: {msg: $msg, note: $note}) {
    _id
    msg
    note
    number
  }
}
    `;
export type UpdateNumberMutationFn = Apollo.MutationFunction<UpdateNumberMutation, UpdateNumberMutationVariables>;

/**
 * __useUpdateNumberMutation__
 *
 * To run a mutation, you first call `useUpdateNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNumberMutation, { data, loading, error }] = useUpdateNumberMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      msg: // value for 'msg'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNumberMutation, UpdateNumberMutationVariables>) {
        return Apollo.useMutation<UpdateNumberMutation, UpdateNumberMutationVariables>(UpdateNumberDocument, baseOptions);
      }
export type UpdateNumberMutationHookResult = ReturnType<typeof useUpdateNumberMutation>;
export type UpdateNumberMutationResult = Apollo.MutationResult<UpdateNumberMutation>;
export type UpdateNumberMutationOptions = Apollo.BaseMutationOptions<UpdateNumberMutation, UpdateNumberMutationVariables>;
export const MyPhoneNumbersDocument = gql`
    query MyPhoneNumbers {
  phoneNumbers {
    _id
    msg
    note
    number
  }
}
    `;

/**
 * __useMyPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useMyPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPhoneNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<MyPhoneNumbersQuery, MyPhoneNumbersQueryVariables>) {
        return Apollo.useQuery<MyPhoneNumbersQuery, MyPhoneNumbersQueryVariables>(MyPhoneNumbersDocument, baseOptions);
      }
export function useMyPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyPhoneNumbersQuery, MyPhoneNumbersQueryVariables>) {
          return Apollo.useLazyQuery<MyPhoneNumbersQuery, MyPhoneNumbersQueryVariables>(MyPhoneNumbersDocument, baseOptions);
        }
export type MyPhoneNumbersQueryHookResult = ReturnType<typeof useMyPhoneNumbersQuery>;
export type MyPhoneNumbersLazyQueryHookResult = ReturnType<typeof useMyPhoneNumbersLazyQuery>;
export type MyPhoneNumbersQueryResult = Apollo.QueryResult<MyPhoneNumbersQuery, MyPhoneNumbersQueryVariables>;