import { useState } from "react";
import { Transition } from "framer-motion";

type Palette = {
  cleanTransition: Transition;
  primaryColorHex: string;
  secondaryColorHex: string;
};

/*
KnowHowHub Color Theme:
Blue
#07568A
rgb(7, 86, 138)
Light Blue
#079FCA
Green
#4C9401
Light Green
#7CAC42
#5C9E56
rgb(92, 158, 86)
Coal - Grey
#535353
 */

const defaultPalette: Palette = {
  cleanTransition: { duration: 0.3, ease: "linear" },
  primaryColorHex: "#4C9401",
  secondaryColorHex: "#7CAC42"
};

export function usePalette() {
  const [palette] = useState<Palette>(defaultPalette);
  return palette;
}
