import React, { FC } from "react";
import { FlatList } from "../components/reusable/FlatList";
import {
  DetailsProps,
  MasterDetail,
} from "../components/reusable/MasterDetail";
import {
  PhoneNumber,
  useMyPhoneNumbersQuery,
  useUpdateNumberMutation,
} from "../generated/graphql";
import { notEmpty } from "../shared/tsutil";
import { useForm } from "react-hook-form";
import { AnimateSharedLayout, motion } from "framer-motion";
import { client } from "../apollo/client";
import { useRealmApp } from "../shared/context/RealmContext";
import {usePalette} from "../shared/hook/usePalette";

interface NumberCardsProps {
  phoneNumber: PhoneNumber;
  updateNumber: (number: Omit<PhoneNumber, "number">) => void;
}

const NumberCard: FC<NumberCardsProps> = ({ phoneNumber, updateNumber }) => {
  const palette = usePalette();

  type FormData = {
    msg: string;
    note: string;
  };
  const { register, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      msg: phoneNumber.msg,
      note: phoneNumber.note,
    },
  });
  const { msg, note } = watch();

  const noteChanged = note !== phoneNumber.note;
  const msgChanged = msg !== phoneNumber.msg;
  const changed = noteChanged || msgChanged;

  const onSubmit = async (data: FormData) => {
    updateNumber({
      _id: phoneNumber._id,
      ...data,
    });
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      className="shadow rounded-md m-4"
    >
      <div className="bg-white py-6 px-4 sm:p-6">
        <div className={`text-xl leading-6 font-medium`} style={{color: palette.primaryColorHex}}>
          {phoneNumber.number}
        </div>
        <div className="mt-6 text-left flex flex-col space-y-6">
          <div className="">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              Note
            </label>
            <textarea
              name="note"
              id="note"
              ref={register()}
              className={`mt-1 block w-full border ${
                noteChanged ? "border-green-400" : "border-gray-300"
              } rounded-md shadow-sm py-2 px-3 focus:outline-none hover:shadow focus:shadow focus:ring-gray-900 focus:border-gray-900 sm:text-sm`}
            />
          </div>
          <div className="">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Message
            </label>
            <textarea
              name="msg"
              id="msg"
              ref={register({
                required: { value: true, message: "Message is required." },
                maxLength: { value: 160, message: "Max length is 160." },
              })}
              className={`mt-1 block w-full border ${
                msgChanged ? "border-green-400" : "border-gray-300"
              } rounded-md shadow-sm py-2 px-3 focus:outline-none hover:shadow focus:shadow focus:ring-gray-900 focus:border-gray-900 sm:text-sm`}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <motion.button
          type="submit"
          disabled={!changed}
          className={`rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
          ${
            changed
              ? "cursor-pointer border-gray-500 bg-green-400 text-white transition duration-500 ease-in-out transform hover:scale-110 hover:bg-green-300"
              : "cursor-not-allowed border-gray-400 bg-transparent text-gray-400"
          }`}
        >
          Save
        </motion.button>
      </div>
    </motion.form>
  );
};

interface NumberDetailsRestProps {
  updateNumber: (number: Omit<PhoneNumber, "number">) => void;
}

const NumberDetails: FC<DetailsProps<PhoneNumber, NumberDetailsRestProps>> = ({
  item,
  rest,
}) => {
  if (!rest?.updateNumber) {
    throw new Error("Must include updateNumber");
  }

  return (
    <AnimateSharedLayout>
      <motion.ul>
        <NumberCard
          phoneNumber={item}
          updateNumber={rest.updateNumber}
          key={item._id}
        />
      </motion.ul>
    </AnimateSharedLayout>
  );
};

export const NumbersPage: FC = () => {
  const { userData } = useRealmApp();
  if (!userData?.realmId) {
    throw new Error("Could not get realm ID");
  }

  const { loading, error, data } = useMyPhoneNumbersQuery({client});
  const [updateNumberMutation] = useUpdateNumberMutation({ client });

  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    console.log(error);
    return <div>Err: {error.message}</div>;
  }

  const numbers = data?.phoneNumbers?.filter(notEmpty)! || [];
  const updateNumber = async (
    phoneNumber: Omit<PhoneNumber, "number">
  ) => {
    await updateNumberMutation({ variables: { ...phoneNumber } });
  };

  return (
    <MasterDetail<PhoneNumber, NumberDetailsRestProps>
      list={FlatList}
      listProps={{
        titleFunc: (number) => number.number,
        descriptionFunc: (number) => number.note,
      }}
      details={NumberDetails}
      items={numbers}
      location="numbers"
      rest={{ updateNumber }}
    />
  );
};
