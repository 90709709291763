import React, { FC, ReactElement } from "react";
import { AnimatePresence, motion, MotionStyle } from "framer-motion";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useBreakpoint } from "../../shared/hook/useBreakpoint";
import { ListProps } from "./FlatList";

export interface DetailsProps<T, R = never> {
  item: T;
  items: T[];
  rest?: R;
}

export interface MasterDetailProps<T extends { _id: string }, R> {
  list: FC<ListProps<T>>;
  listProps: Omit<ListProps<T>, "items" | "location">;
  details: FC<DetailsProps<T, R>>;
  emptyDetails?: FC;
  items: T[];
  location: string;
  rest?: R;
}

const pageStyle: MotionStyle = {
  position: "absolute",
};
const leftPageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100vw",
  },
};
const rightPageVariants = {
  initial: {
    opacity: 0,
    x: "100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "100vw",
  },
};
const pageTransition = {
  ease: "easeInOut",
  duration: 0.25,
};

export function MasterDetail<T extends { _id: string }, R>(
  props: MasterDetailProps<T, R>
): ReactElement<MasterDetailProps<T, R>> {
  const {
    list: ListComponent,
    details: DetailsComponent,
    emptyDetails: emptyDetailsComponent,
    items,
    location,
    listProps,
    rest,
  } = props;
  const currLocation = useLocation();
  const idMatch = useRouteMatch<{ id: string }>(`/${location}/:id`);

  const item =
    idMatch !== null
      ? items.find((item) => {
          return item._id === idMatch.params.id;
        })
      : undefined;

  const emptyDetails = emptyDetailsComponent ? (
    emptyDetailsComponent({})
  ) : (
    <div className="flex flex-row space-x-4 h-40 justify-center items-center text-gray-500">
      <h2>Select an item</h2>
    </div>
  );

  const makeSmBody = () => (
    <div className="h-full w-full">
      <AnimatePresence>
        <Switch location={currLocation} key={currLocation.pathname}>
          <Route exact path={`/${location}`}>
            <motion.div
              style={pageStyle}
              initial="initial"
              animate="in"
              exit="out"
              variants={leftPageVariants}
              transition={pageTransition}
              className="w-full"
            >
              <ListComponent {...{ location, items, ...listProps }} />
            </motion.div>
          </Route>
          <Route path={`/${location}/:id`}>
            <motion.div
              style={pageStyle}
              initial="initial"
              animate="in"
              exit="out"
              variants={rightPageVariants}
              transition={pageTransition}
              className="w-full"
            >
              {item ? (
                <DetailsComponent item={item} items={items} rest={rest} />
              ) : (
                emptyDetails
              )}{" "}
            </motion.div>
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );

  const makeFullBody = () => (
    <div className="flex flex-row h-full w-full">
      <div className="w-1/4 border-r">
        <ListComponent {...{ location, items, ...listProps }} />
      </div>
      <div className="w-3/4 overflow-y-scroll">
        {item ? (
          <DetailsComponent item={item} items={items} rest={rest} />
        ) : (
          emptyDetails
        )}
      </div>
    </div>
  );

  const breakpoint = useBreakpoint();
  return breakpoint === "sm" ? makeSmBody() : makeFullBody();
}
